import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faPlusCircle, faSignOutAlt, faToolbox, faWrench } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";
import "./product.css";


interface Project {
  prjdesc: string;
  id: number;
  prjname: string;
}

const Dashboard = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [prjname, setPrjName] = useState("");
  const [prjdescription, setPrjDescription] = useState("");
  const [color, setColor] = useState("");
  const [nameError, setNameError] = useState("");
  const [descError, setDescError] = useState("");


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "prjname") {
      setPrjName(value);
      if (value.length < 5 || value.length > 50) {
        setNameError('Site name must be between 5 and 50 characters.');
      } else {
        setNameError('');
      }
    }
    if (name === "prjdescription") {
      setPrjDescription(value);
      if (value.length < 5 || value.length > 500) {
        setDescError('Site description must be between 5 .');
      } else {
        setDescError('');
      }
    }


    if (name === "color") setColor(value);
  };

  const handleBlur = () => {
    if (prjname.length < 5 || prjname.length > 50) {
      setNameError('Project name must be between 5 and 50 characters.');
    } else {
      setNameError('');
    }
  };

  const handleClick = () => {
    let nameError = "";
    let descError = "";
    if (!prjname) {
      nameError = "Site name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(prjname)) {
      nameError = "Site name must contain only alphabets and spaces";
    }else if (prjname.length <5){
      nameError = "Site name must be at least 5 characters long";
    }
    if (!prjdescription) {
      descError = "Site description is required";
    } else if (prjdescription.length <5){
      descError = "Site description must be at least 5 characters long";
    }
    if (nameError || descError) {
      setNameError(nameError);
      setDescError(descError);
      return;
    }
    const req = {
      sitename: prjname,
      sitedesc: prjdescription,
    };
    callApi("POST", "site_add.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Site Added Successfully!");
          setShow(false);
          setPrjName("");
          setPrjDescription("");
          getProjectDetails();
  getSiteengineer();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [state, setState] = useState({
    SiteOptionselect: [],
    ToolOptionselect:[],
    siteShowdetails:[],
    SiteSelect:[],
  });

  useEffect(() => {
    console.log("siteShowdetails", state.siteShowdetails);
  }, [state.siteShowdetails]);
  
  const getProjectDetails = () => {
    callApi("GET", "site_expense_bal.php", "")
      .then((res) => res.data)
      .then((res) => {
        if (res.statusCode === 200) {
          setState((prevState) => ({
            ...prevState,
            siteShowdetails: res.responseJson.data,
          }));
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  const logout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      navigate(`/`);
    }
  };
  


  // expensemodal //
  const [Expenseshow, setExpenseShow] = useState(false);
  const [Paymentshow, setPaymentShow] = useState(false);

  // const [toolshow,setToolshow] = useState(false);

  const ExpenseShow = () => setExpenseShow(true);
  const PaymentShow = () => setPaymentShow(true);

  // const ToolShow =() => setToolshow(true);

  const ToolShow = () =>{
    navigate(`/ToolsManagement`);
  }

  const ToolClose = () => {
    settoolError("");
    settoolsiteError("");
    // setToolshow(false);
  }
  
  const ExpenseClose = () => {
    setExsiteError("");
    setExdateError("");
    setExcatError("");
    setExdescError("");
    setExamountError("");
    setExpenseShow(false);
  }
  const PaymentClose= () => {
    setPaysiteError("");
    setPaydateError("");
    setPaycatError("");
    setPaydescError("");
    setPayamountError("");
    setPaymentShow(false);
  }
    //expense data //
    const [expensesite, setExsite] = useState("");
    const [expensedata, setExdate] = useState("");
    const [expensecategery, setExCategery] = useState("");
    const [expensedescription, setExdesc] = useState("");
    const [expenseamount, setExamount] = useState("");
  
    const [ExsiteError, setExsiteError] = useState("");
    const [ExdateError, setExdateError] = useState("");
    const [ExcatError, setExcatError] = useState("");
    const [ExdescError, setExdescError] = useState("");
    const [ExamountError, setExamountError] = useState("");

  const handleExpenseChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "expensesite") setExsite(value);
    if (name === "expensedata") setExdate(value);
    if (name === "expensecategery") setExCategery(value);
    if (name === "expensedescription") {
      setExdesc(value);
      if (value.length < 5 || value.length > 50) {
        setExdescError('Description  must be between 5  characters.');
      } else {
        setExdescError('');
      }
    }
    // if(name === "expensedescription")setExdesc(value);

    if (name === "expenseamount" && parseFloat(value) >= 0) {
      setExamount(value);
  }
  };

  const handleClickExpense = () => {
    let ExsiteError = "";
    let ExdateError = "";
    let ExcatError = "";
    let ExdescError = "";
    let ExamountError = "";
    if (!expensesite) {
      ExsiteError = "Site name is required";
    } 
    if (!expensedata) {
      ExdateError = "Date is required";
    } 
    if (!expensecategery) {
      ExcatError = "Category is required";
    } 
    if (!expensedescription) {
      ExdescError = "Description is required";
    } 
    if (!expenseamount) {
      ExamountError = "Amount is required";
    } 
    if (ExsiteError || ExdateError ||ExcatError || ExdescError || ExamountError) {
      setExsiteError(ExsiteError);
      setExdateError(ExdateError);
      setExcatError(ExcatError);
      setExdescError(ExdescError);
      setExamountError(ExamountError);
      return;
    }
    const req = {
      site: expensesite,
      date: expensedata,
      categery:expensecategery ,
      description:expensedescription,
      amount:expenseamount,
    };
    callApi("POST", "expense_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Expense Added Successfully!");
          setExpenseShow(false);
          getProjectDetails();
          getSiteengineer();
          setExsite("");
          setExdate ("");
          setExCategery("");
          setExdesc ("");
          setExamount("");
                } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickExpenseNew = () => {
    let ExsiteError = "";
    let ExdateError = "";
    let ExcatError = "";
    let ExdescError = "";
    let ExamountError = "";
    if (!expensesite) {
      ExsiteError = "Site name is required";
    } 
    if (!expensedata) {
      ExdateError = "Date is required";
    } 
    if (!expensecategery) {
      ExcatError = "Category is required";
    } 
    if (!expensedescription) {
      ExdescError = "Description is required";
    } 
    if (!expenseamount) {
      ExamountError = "Amount is required";
    } 
    if (ExsiteError || ExdateError ||ExcatError || ExdescError || ExamountError) {
      setExsiteError(ExsiteError);
      setExdateError(ExdateError);
      setExcatError(ExcatError);
      setExdescError(ExdescError);
      setExamountError(ExamountError);
      return;
    }
    const req = {
      site: expensesite,
      date: expensedata,
      categery:expensecategery ,
      description:expensedescription,
      amount:expenseamount,
    };
    callApi("POST", "expense_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Expense Added Successfully!");
          getProjectDetails();
          getSiteengineer();
          setExsite("");
          setExdate ("");
          setExCategery("");
          setExdesc ("");
          setExamount("");
          setExpenseShow(true);
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

      //payment setvalue data //
      const [paymentsite, setPaysite] = useState("");
      const [paydata, setPaydate] = useState("");
      const [paycategery, setPayCategery] = useState("");
      const [paydescription, setPaydesc] = useState("");
      const [payamount, setPayamount] = useState("");
    
      const [PaysiteError, setPaysiteError] = useState("");
      const [PaydateError, setPaydateError] = useState("");
      const [PaycatError, setPaycatError] = useState("");
      const [PaydescError, setPaydescError] = useState("");
      const [PayamountError, setPayamountError] = useState("");
  
    const handlePaymentChange = (e: any) => {
      const { name, value } = e.target;
      if (name === "paymentsite") setPaysite(value);
      if (name === "paydata") setPaydate(value);
      if (name === "paycategery") setPayCategery(value);
      // if(name === "paydescription")setPaydesc(value);
      if (name === "paydescription") {
        setPaydesc(value);
        if (value.length < 5 || value.length > 50) {
          setPaydescError('Description  must be between 5  characters.');
        } else {
          setPaydescError('');
        }
      }
      if(name === "payamount" && parseFloat(value) >= 0){
        setPayamount(value);
      }
    };
  
    const handleClickPayment = () => {
      let PaysiteError = "";
      let PaydateError = "";
      let PaycatError = "";
      let PaydescError = "";
      let PayamountError = "";

      if (!paymentsite) {
        PaysiteError = "Site name is required";
      } 
      if (!paydata) {
        PaydateError = "Date is required";
      } 
      if (!paycategery) {
        PaycatError = "Category is required";
      } 
      if (!paydescription) {
        PaydescError = "Description is required";
      } 
      if (!payamount) {
        PayamountError = "Amount is required";
      } 
      if (PaysiteError || PaydateError ||PaycatError || PaydescError || PayamountError) {
        setPaysiteError(PaysiteError);
        setPaydateError(PaydateError);
        setPaycatError(PaycatError);
        setPaydescError(PaydescError);
        setPayamountError(PayamountError);
        return;
      }
      
      const req = {
        paysite: paymentsite,
      paydate: paydata,
      paycategery:paycategery ,
      paydescription:paydescription,
      payamount:payamount,
      };
  
      callApi("POST", "payment_insert.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["success"] === true) {
            showSuccess("Success!", "Payment Added Successfully!");
            setPaymentShow(false);
            setPaysite("");
            setPaydate("");
            setPayCategery("");
            setPaydesc("");
            setPayamount("");
            getProjectDetails();
            getSiteengineer();

          } else {
            showError("Failed", "Not Inserted !");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleClickPaymentNew = () => {
      let PaysiteError = "";
      let PaydateError = "";
      let PaycatError = "";
      let PaydescError = "";
      let PayamountError = "";

      if (!paymentsite) {
        PaysiteError = "Site name is required";
      } 
      if (!paydata) {
        PaydateError = "Date is required";
      } 
      if (!paycategery) {
        PaycatError = "Category is required";
      } 
      if (!paydescription) {
        PaydescError = "Description is required";
      } 
      if (!payamount) {
        PayamountError = "Amount is required";
      } 
      if (PaysiteError || PaydateError ||PaycatError || PaydescError || PayamountError) {
        setPaysiteError(PaysiteError);
        setPaydateError(PaydateError);
        setPaycatError(PaycatError);
        setPaydescError(PaydescError);
        setPayamountError(PayamountError);
        return;
      }
      
      const req = {
        paysite: paymentsite,
      paydate: paydata,
      paycategery:paycategery ,
      paydescription:paydescription,
      payamount:payamount,
      };
  
      callApi("POST", "payment_insert.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["success"] === true) {
            showSuccess("Success!", "Payment Added Successfully!");
            setPaymentShow(true);
            setPaysite("");
            setPaydate("");
            setPayCategery("");
            setPaydesc("");
            setPayamount("");
            getProjectDetails();
            getSiteengineer();

          } else {
            showError("Failed", "Not Inserted !");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const [tool, setTool] = useState("");
    const [toolsite,setToolSite]=useState("");
    const[toolqty,setToolqty]=useState("");
    const [ToolError, settoolError] = useState("");
    const [ToolSiteError, settoolsiteError] = useState("");
    const [ToolqtyError, settoolqtyError] = useState("");

//useeffect site tool select options value//
const [siteengineerDetails, setsiteengineerDetails] = useState({
  id: "",
  worktype: "",
});


const [TotalExpence, setTotalExpence] = useState(null);
const [TotalBal, setTotalBal] = useState(null);

useEffect(() => {
  getSiteengineer();
  console.log("siteengineerDetails:", siteengineerDetails);
}, [siteengineerDetails.worktype]);

const getSiteengineer = () => {
  callApi("POST", "totalbal_select.php", "")
  .then((res) => {
    if (res.data.statusCode === 200) {
      setTotalExpence(res.data.responseJson.data[0].total_expense);
      setTotalBal(res.data.responseJson.data[0].total_balance);
    }
  })
  .catch((err) => {
    console.log(err);
  });

  callApi("POST", "site_select.php", "")
    .then((res) => {
      if (res.data.statusCode === 200) {
        setState((prevState) => ({
          ...prevState,
          SiteOptionselect: res.data.responseJson.data,
        }));
      }
    })
    .catch((err) => {
      console.log(err);
    });

    callApi("POST", "categery_select.php", "")
    .then((res) => {
      if (res.data.statusCode === 200) {
        setState((prevState) => ({
          ...prevState,
          SiteSelect: res.data.responseJson.data,
        }));
      }
      console.log("SiteSelect",state.SiteSelect);
    })
    .catch((err) => {
      console.log(err);
    });

    callApi("POST", "tool_select.php", "")
    .then((res) => {
      if (res.data.statusCode === 200) {
        setState((prevState) => ({
          ...prevState,
          ToolOptionselect: res.data.responseJson.data,
        }));

      }
    })
    .catch((err) => {
      console.log(err);
    });

};

const cardhandleClick = (project: any) => {
  navigate(`/sitedetails?id=${project.id}`);
};
const categery = () =>{
  navigate(`/Category`); 

}


  return (
    <main>
      <header>
        <div className="">
          <h1>JMJ</h1>
          <Dropdown className="d-inline mx-2 ddrop"  >
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faGear} className="icon printicon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="padd" onClick={categery}>       <FontAwesomeIcon icon={faPlusCircle} className="icon" />Category</Dropdown.Item>
              <Dropdown.Item className="padd" onClick={logout}> <FontAwesomeIcon icon={faSignOutAlt} className="icon" />  Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
 
        </div>
      </header>

      <div className="dashcontiner">
        <Row className="scrollRow web_view">
          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary jmjbutton mobviewbtn"  onClick={handleShow}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Site
            </Button>
          </Col>
          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary jmjbutton mobviewbtn"  onClick={ToolShow}>
              Tools
            </Button>
          </Col>

          <Col lg="2" md="2" className="flexbtn">
          <div  className="expencecar">
                    <div className="card-body">
                      <h6 className="card-title">Balance</h6>
                      <p className="card-text">Rs.{TotalBal}</p>
                    </div>
                  </div>
          </Col>
          <Col lg="2" md="2" className="flexbtn">
          <div  className="expencecar">
                    <div className="card-body">
                      <h6 className="card-title">Expense</h6>
                      <p className="card-text">Rs.{TotalExpence || 0}</p>
                    </div>
                  </div>
          </Col>
          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary jmjbutton"  onClick={ExpenseShow}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Expense
            </Button>
          </Col>
          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary jmjbutton"  onClick={PaymentShow}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
            Payment Made
            </Button>
          </Col>

          
        </Row>
        <Row className="scrollRow mob_view">
          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary jmjbutton mobviewbtn"  onClick={handleShow}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Site
            </Button>
          </Col>
          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary jmjbutton mobviewbtn"  onClick={ToolShow}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Tools
            </Button>
          </Col>

          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary jmjbutton"  onClick={ExpenseShow}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Expense
            </Button>
          </Col>
         
          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary jmjbutton"  onClick={PaymentShow}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Payment Made
            </Button>
          </Col>

          <Col lg="2" md="2" className="flexbtn">
          <div  className="expencecar">
                    <div className="card-body">
                      <h6 className="card-title">Expense</h6>
                      <p className="card-text">Rs.{TotalExpence || 0}</p>
                    </div>
                  </div>
          </Col>
          <Col lg="2" md="2" className="flexbtn">
          <div  className="expencecar">
                    <div className="card-body">
                      <h6 className="card-title">Balance</h6>
                      <p className="card-text">Rs.{TotalBal}</p>
                    </div>
                  </div>
          </Col>
        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
            {state.siteShowdetails &&
            state.siteShowdetails.length > 0 &&
            state.siteShowdetails.map((project:any, index) => {
              return (
<Col lg="3" md="3" key={project.id}  className="flex">
                  <div
                    className="card"
                    onClick={() => cardhandleClick(project)}                  
                  >
                    <div className="card-body">
                      <h5 className="card-title">{project.name}</h5>
                      <div className="flexamount">
                      <p className="card-text">Rs.{project.total_expense || 0} </p>
                      <p className="card-text">Bal.{project.balance_amt}</p>
                      </div>
                    </div>
                  </div>
                </Col>
                    );
                  })}
        </Row>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Site</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name* </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Site Name"
                name="prjname"
                minLength={5}
                maxLength={50}
                required
                value={prjname}
                onChange={handleChange}
                onFocus={() => setNameError("")}
              />
              <div className="text-danger">{nameError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Description*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="prjdescription"
                minLength={5}
                maxLength={100}
                required
                value={prjdescription}
                onChange={handleChange}
                onFocus={() => setDescError("")}
              />
              <div className="text-danger">{descError}</div>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClick}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={Expenseshow} onHide={ExpenseClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name* </label>
               <Form.Select
                              aria-label="Default select example"
                              name="expensesite"
                              onChange={handleExpenseChange}
                              value={expensesite}
                              onFocus={() => setExsiteError("")}
                            >
                              <option value="">Select Site</option>
                              {state.SiteOptionselect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.id}
                                >
                                  {categery.site}
                                </option>
                              ))}
                            </Form.Select>
              <div className="text-danger">{ExsiteError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Date*</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Description"
                name="expensedata"
                minLength={5}
                maxLength={100}
                required
                value={expensedata}
                onChange={handleExpenseChange}
                onFocus={() => setExdateError("")}
              />
              <div className="text-danger">{ExdateError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Category*</label>
              <Form.Select
                              aria-label="Default select example"
                              name="expensecategery"
                              onChange={handleExpenseChange}
                              onFocus={() => setExcatError("")}
value={expensecategery}
                            >
                              <option value="">Select Category</option>
                              {state.SiteSelect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.categery}
                                >
                                  {categery.categery}
                                </option>
                              ))}
                            </Form.Select>
              <div className="text-danger">{ExcatError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Description*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="expensedescription"
                minLength={5}
                maxLength={100}
                value={expensedescription}
                required
                onChange={handleExpenseChange}
                onFocus={() => setExdescError("")}
              />
              <div className="text-danger">{ExdescError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Amount*</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Amount"
                name="expenseamount"
                minLength={5}
                maxLength={100}
                value={expenseamount}
                required
                onChange={handleExpenseChange}
                onFocus={() => setExamountError("")}
              />
              <div className="text-danger">{ExamountError}</div>
            </div>
            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickExpense}
              >
                Save
              </button>
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickExpenseNew}
              >
                Save & New
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={Paymentshow} onHide={PaymentClose}>
        <Modal.Header closeButton>
          <Modal.Title>
Payment Made

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name*</label>
            
               <Form.Select
                              aria-label="Default select example"
                              name="paymentsite"
                              onChange={handlePaymentChange}
                              onFocus={() => setPaysiteError("")}
                              value={paymentsite}
                            >
                               <option value="">Select Site</option>
                              {state.SiteOptionselect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.id}
                                >
                                  {categery.site}
                                </option>
                              ))}
                            </Form.Select>
              <div className="text-danger">{PaysiteError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Date*</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Description"
                name="paydata"
                minLength={5}
                maxLength={100}
                required
                onChange={handlePaymentChange}
                onFocus={() => setPaydateError("")}
                value={paydata}
              />
              <div className="text-danger">{PaydateError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Category*</label>
              <Form.Select
                              aria-label="Default select example"
                              name="paycategery"
                              onChange={handlePaymentChange}
                              onFocus={() => setPaycatError("")}
value={paycategery}
                            >
                              <option value="">Select Category</option>
                              {state.SiteSelect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.categery}
                                >
                                  {categery.categery}
                                </option>
                              ))}

                            </Form.Select>
              <div className="text-danger">{PaycatError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Description*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="paydescription"
                minLength={5}
                maxLength={100}
                required
                onChange={handlePaymentChange}
                onFocus={() => setPaydescError("")}
                value={paydescription}
              />
              <div className="text-danger">{PaydescError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Amount*</label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Amount"
                name="payamount"
                minLength={5}
                maxLength={100}
                required
                onChange={handlePaymentChange}
                onFocus={() => setPayamountError("")}
                value={payamount}
              />
              <div className="text-danger">{PayamountError}</div>
            </div>
            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickPayment}
              >
                Save
              </button>
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickPaymentNew}
              >
                Save & New
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </main>
  );
};

export default Dashboard;
