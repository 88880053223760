import { faCircleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CSVLink } from 'react-csv';


interface DataItem {
    created_date: string;
    engineer_name: string;
    site_name:string;
    worktype: string;
    categery: string;
    amount: string;
    description: string;
}


interface Props {
    data: DataItem[];
}


const ExpenseExcelComponent: React.FC<Props> = ({ data }) => {
    const csvData = data.map((item :any, index :any) => {
       
        return {
            "Sl.No": index + 1,
            "Date":item.date,
            "category": item.category,
            "Description":item.Desc,
            "Amount":item.Amount,
        };
    });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

    return (
        <CSVLink data={csvData}  filename={`JMJ Expense Report - ${formattedDate}.csv`}>
                 <FontAwesomeIcon
              icon={faCircleDown}
              className="icon downexcel"
              style={{color:"#000000 !important"}}
            />
        </CSVLink>
    );
};

export default ExpenseExcelComponent;
