import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleLeft,
  faGear,
  faPlusCircle,
  faSearch,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";

const Tooladd = () => {
  const navigate = useNavigate();
  const [ToolModal, setToolModal] = useState(false);
  const [colorselectmodal, setToolselectmodal] = useState(false);
  const [tool, setTool] = useState("");
  const [toolError, setToolError] = useState("");
  const [state, setState] = useState({
    ToolDetailstable: [],
  });
  const [selectEditValue, setToolEditvalue] = useState({ id: "", tool: ""});
  const handleToolshow = () => setToolModal(true);
  const toolModalClose = () => {
    setToolModal(false);
  };
  const handleselectEditValueClose = () => {
    setToolselectmodal(false);
    setToolEditvalue({id: "", tool: ""});
  };


  const handleChange = (e: any) => {
    const { name, value } = e.target;
  
    if (name === "tool") {
      if (/^[A-Za-z]+$/.test(value) || value === "") {
        setTool(value);
        if (value.length < 5 || value.length > 50) {
          setToolError('Tool name must be between 5 and 50 characters.');
        } else {
          setToolError('');
        }
      }
    }
  };
  
  const getProjectDetails = () => {
    callApi("POST", "tool_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("toolresponse", res.data.responseJson.data);
          setState({ ToolDetailstable: res.data.responseJson.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const colorhandleClick = () => {
    let toolError = "";
    if (!tool) {
      toolError = "Tool is required";
    } 
    if (toolError) {
      setToolError(toolError);
      return;
    }
    const req = {
      tool: tool,
    };
    callApi("POST", "tool_add.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          getProjectDetails();
          setToolModal(false);
          setTool("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const colorhandleClickNew = () => {
    let toolError = "";
    if (!tool) {
      toolError = "Tool is required";
    } 
    if (toolError) {
      setToolError(toolError);
      return;
    }
    const req = {
      tool: tool,
    };
    callApi("POST", "tool_add.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          getProjectDetails();
          setToolModal(true);
          setTool("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      navigate(`/`);
      showSuccess("Success!", "Logout Successfully!");
    }
  
  };

  useEffect(() => {
    getProjectDetails();
    console.log("selectEditValue:", selectEditValue);
  }, [selectEditValue.tool]);

  const ToolEditModalshow = (id: any) => {
    setToolselectmodal(true);
    console.log("Tool Id", id);
    let req = {
      toolId: id,
    };

    callApi("POST", "tool_editselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          console.log("Tool_idselect", response.responseJson.data);
          let res = response.responseJson.data[0];
          setToolEditvalue(res);
          console.log("setselectEditValue", selectEditValue);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const back = () => {
    navigate(`/ToolsManagement`);

  };
  const handleChangecolor = (event: any) => {
    console.log("type", event);
    setToolEditvalue({"tool": event.target.value, "id": selectEditValue.id});
    console.log(" event.target.value", event.target.value);
  };

  const updatecolor = (id: any) => {
    let req = {
      toolId: selectEditValue.id,
      tool_name: selectEditValue.tool,
    };
    console.log("req", req);
    
    callApi("POST", "tool_idupdate.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Tool Updated Successfully!");
        getProjectDetails();
        setToolselectmodal(false);
        setToolEditvalue({id: "", tool: ""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  };

  const deletecolor = (id: any) => {

    const confirmLogout = window.confirm("Are you sure you want to delete?");
    if (confirmLogout) {
      let req = {
        toolId:id,
      };
      console.log("req", req);
      
      callApi("POST", "tool_iddelete.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Deleted Successfully!");
          getProjectDetails();
          setToolEditvalue({id: "", tool: ""});
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  
    }


  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredData = state.ToolDetailstable.filter((item: any) =>
    Object.values(item).some((value) =>
      (value as string).toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );


  const { ToolDetailstable } = state;

  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faCircleLeft}
            className="icon backicon"
            onClick={back}
          />
          <h1 className="heading">Manage Tools</h1>

          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faSignOutAlt} className="icon printicon" onClick={logout} />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </header>

      <div className="dashcontiner">
        <Row className="scrollRow">
         
          <Col lg="12" md="12" className="" style={{display:"flex",justifyContent:"center"}}>
              <div className="form-field" style={{margin:"2%"}}>
<FontAwesomeIcon icon={faSearch} className="icon" />
<input type="text"
 placeholder="Search here"
 onChange={(e) => handleSearch(e.target.value)}
/>
</div>

          </Col>
        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="2" md="2" className=""></Col>

          <Col lg="8" md="8" className="">
            {filteredData.length >0 &&
            <>
             <Table
              responsive="md lg"
              striped
              bordered
              hover
              style={{ width: "100% !important",textAlign:"center" }}
            >
            
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Tool</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
               
 {filteredData.map((details: any, index: any) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{details.tool}</td>
                          <td>
                            <Dropdown className="d-inline mx-2">
                              <Dropdown.Toggle
                                id="dropdown-autoclose-true"
                                className="actionbutton"
                              >
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => ToolEditModalshow(details.id)}
                                >
                                  Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() =>deletecolor(details.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
   ))}
           
              </tbody>
             
            </Table>
            </> }
           
          </Col>
          <Col lg="2" md="2" className=""></Col>
        </Row>
      </div>

      <Modal show={ToolModal} onHide={toolModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label"> Add Tool* </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Tool"
                name="tool"
                minLength={5}
                maxLength={50}
                value={tool}
                required
                onChange={handleChange}
                onFocus={() => setToolError("")}
                pattern="^[A-Za-z]+$"
              />
              <div className="text-danger">{toolError}</div>
            </div>

            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={colorhandleClick}
              >
                Save
              </button>
              <button
                className="btn btn-primary modalsubbutton"
                onClick={colorhandleClickNew}
              >
                 Save & New
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={colorselectmodal} onHide={handleselectEditValueClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Tool </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Tool"
                name="color"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChangecolor}
                onFocus={() => setToolError("")}
                value={selectEditValue.tool}
              />
              <div className="text-danger">{toolError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => updatecolor(selectEditValue.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Tooladd;
