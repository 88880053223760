import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./component/auth/login";
import Dashboard from "./component/auth/dashboard";
import Tooladd from "./component/auth/coloradd";
import Test from "./component/auth/test";
import ToolsManagement from "./component/auth/tools_management";
import SiteDetails from "./component/auth/sitedetails";
import Categery from "./component/auth/categery";


const App = () => {

  
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/Tooladd" element={<Tooladd />} />
          <Route path="/ToolsManagement" element={< ToolsManagement />} />
          <Route path="/test" element={< Test />} />
          <Route path="/SiteDetails" element={< SiteDetails/>} />
          <Route path="/Category" element={< Categery />} />

        </Routes>
      </Router>
      <Router basename="/dashboard">
</Router>

    </div>
  );
};

export default App;
