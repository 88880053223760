import React, { useRef } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import { saveAs } from '@progress/kendo-file-saver'; // Import saveAs method
import { useReactToPrint } from "react-to-print";
import "./prj_details.css";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import input from "antd/es/input";


export default function Test() {
  // const pdfExportComponent = React.useRef<PDFExport>(null);

  // const printRef = useRef<HTMLDivElement | null>(null);
  // const downloadPDF = useReactToPrint({
  //   content: () => printRef.current ? printRef.current : null
  // });
  

  <style>
  {`
  
  `}
</style>

// const downloadPDF = () => {
//   const userConfirmation = window.confirm("Do you want to download this as a PDF?");
//   if (userConfirmation) {
//     const content = document.querySelector(".pdf-export");
//     if (content instanceof HTMLElement) {
//       html2canvas(content)
//         .then(canvas => {
//           const imgData = canvas.toDataURL('image/png');
//           const pdf = new jsPDF();
//           const imgWidth = 210;
//           const imgHeight = (canvas.height * imgWidth) / canvas.width;

//           pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
//           pdf.save(`download.pdf`);
//         });
//     } else {
//       console.error("Content element not found or not an HTMLElement.");
//     }
//   }
// };



// const downloadPDF = () => {
//   const content = document.querySelector('.pdf-export') as HTMLElement;
//   if (!content) {
//     console.error('PDF export content not found');
//     return;
//   }

//   html2canvas(content, { scrollY: -window.scrollY })
//     .then(canvas => {
//       const contentHeight = content.clientHeight;
//       const pageHeight = canvas.height;
//       let pdf = new jsPDF('p', 'px', 'a4');
//       let position = 0;

//       while (position < contentHeight) {
//         pdf.addImage(canvas, 0, position, canvas.width, pageHeight);
//         position -= pageHeight;
//         if (position < contentHeight) {
//           pdf.addPage();
//         }
//       }

//       pdf.save('download.pdf');
//     })
//     .catch(error => {
//       console.error('Error generating PDF:', error);
//     });
// };


const downloadPDF = () => {
  const input = document.querySelector('.pdf-export') as HTMLElement;
  
  if (input) {
    html2canvas(input)
      .then((canvas) => {
        // Convert canvas to image data URL
        const imgData = canvas.toDataURL('image/jpeg');
        
        const pdf = new jsPDF();
        // Add the image data directly to the PDF
        // pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("download.pdf");
      });
  } else {
    console.error("Element with ID 'divToPrint' not found.");
  }
}


  return (
    <div>
      <button onClick={downloadPDF}>Export PDF</button>

        <div className="page pdf-export" >
        <div className="">
            <div className="grid-container">
              <div className="grid-item">
                <div className="">
                  <p className="">டு</p>
                  <div className="">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
           
              <div className="grid-item">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
             
              <div className="grid-item">
              <div className="">
                <div className="category-image data ">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              </div>

              <div className="grid-item">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              <div className="grid-item">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              <div className="grid-item">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              <div className="grid-item">
                <div className="">
                  <p className="">டு</p>
                  <div className="">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
           
              <div className="grid-item">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
             
              <div className="grid-item">
              <div className="">
                <div className="category-image data ">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              </div>

              <div className="grid-item">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              <div className="grid-item">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              <div className="grid-item">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="category-image">
                  <p className="svgletter svgtext">டு</p>
                  <div className="square-box">
                    <div style={{ transform: "rotate(272deg)" }}>
                      <span>600</span>
                    </div>
                    <svg className="shape rectangle" width="310.7142857142857px" height="310.7142857142857px">
                      <rect width="285.7142857142857px" height="285.7142857142857px" fill="#ffffff" stroke="black" x="10" y="10"></rect>
                      <svg>
                        <rect fill="#ffffff" stroke="black" strokeWidth="2" x="25.714285714285715" y="1" width="20" height="20"></rect>
                        <text x="29.714285714285715" y="17" fill="black" fontSize="25">x</text>
                      </svg>
                      <line x1="10" y1="295.7142857142857" x2="35" y2="260.7142857142857" stroke="#000" strokeWidth="4"></line>
                      <text x="32" y="255.71428571428572" fill="#000000">550</text>
                    </svg>
                  </div>
                  <div style={{ textAlign: "center" }}><span>600</span></div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
    </div>
  );
}
