import React, { Component, useState } from "react";
import "./login.css";
import { CardBody, Col, Row } from "react-bootstrap";
import bg1 from "../../assets/bg1.svg";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const onchange = (e:any) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      setEmailError("");
    } else if (name === "password") {
      setPassword(value);
      setPasswordError("");
    }
  };

  const handleClick = () => {
    
    let emailError = "";
    let passwordError = "";

    if (!email) {
      emailError = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Invalid email format";
    }

    if (!password) {
      passwordError = "Password is required";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return;
    }

    let req = {
      email,
      password,
    };

    sessionStorage.setItem("useremail", email);
    callApi("POST", "login.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Login Successfully!");
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        } else {
          showError("Failed", "Email & Password are not matched!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <main>
      <div className="main">
        <div className="container">
          <Row>
            <Col lg="6" md="6" className="text-center bg-blue p-4 colbg">
              <img alt="Logo" className="img-fluid" width="400px" src={bg1} />
            </Col>
            <Col lg="6" md="6" className="text-center bg-blue p-4 centercol">
              <CardBody className="">
                <div className="loginform">
                  <h3>Sign In</h3>
                  <div className="labeldiv">
                    <label className="label">Email* </label>
                    <div className="form-login"  >
                    <input
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      required
                      value={email}
                      onChange={onchange}
                    />
                    </div>
                    <div className="text-danger">{emailError}</div>
                  </div>
                  <div className="labeldiv">
                    <label className="label">Password*</label>
                    <div className="form-login"  >
                    <input
                          type={showPassword ? 'text' : 'password'}
                      placeholder="Enter Password"
                      name="password"
                      required
                      value={password}
                      onChange={onchange}
                    />
                      <FontAwesomeIcon
        icon={showPassword ? faEye : faEyeSlash}
        className="icon eyeicon"
        onClick={togglePasswordVisibility}
      />
      </div>
                    <div className="text-danger">{passwordError}</div>
                  </div>
                  <br />
                  <div className="d-grid">
                    <button
                      className="btn btn-primary subbutton"
                      onClick={handleClick}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </CardBody>
            </Col>
          </Row>
        </div>
      </div>
    </main>
  );
}
